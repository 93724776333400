// TradingChart.js
import React, { useEffect, useRef, useState } from 'react';
import { ColorType, createChart, LineStyle } from 'lightweight-charts';
import io from 'socket.io-client';

const TradingChart = () => {
    const chartContainerRef = useRef(null);
    const chartContainerRefHisto = useRef(null);
    const chartContainerRefHisto2 = useRef(null);

    const candlestickSeriesRef = useRef(null);
    const histoSeriesRef = useRef(null);
    const histoSeriesRef2 = useRef(null);
    const [tooltip, setTooltip] = useState(null);
    const [ksi_text, setKSIText] = useState("");
    const [kcx_text, setKCXText] = useState("");
    const [symbol, setSymbol] = useState("");
    const [candleInfo, setCandleInfo] = useState(null); // State to store candle info

    const [priceLines, setPriceLines] = useState([]);

    useEffect(() => {
        // Create the chart
        const chart1 = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: window.screen.height*0.59,
            //handleScale: false, // Disable zooming
            //handleScroll: false, // Disable scrolling
            layout: {
                background: { type: ColorType.Solid, color: "#131722" },
                textColor: '#D9D9D9',
                fontSize:10
            },
            grid: {
                vertLines: { color: '#2B2B43' },
                horzLines: { color: '#2B2B43' },
            },
            crosshair: {
                mode: 0,
                vertLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
                horzLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
            },
            rightPriceScale: { borderColor: '#2B2B43' },
            timeScale: {
                borderColor: '#4A4A6A',
                timeVisible: true,
                secondsVisible: true,
                minBarSpacing: 0.5,
                barSpacing: 5,
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    const date = new Date(time * 1000);
                    const day = date.getDate();
                    const month = date.toLocaleString(locale, { month: 'short' });
                    const hour = String(date.getHours()).padStart(2, '0');
                    const minute = String(date.getMinutes()).padStart(2, '0');
                    return `${day} ${month} ${hour}:${minute}`;
                },
                ticksVisible: true,
                rightOffset:10,
            },
        });

        // Add candlestick series
        candlestickSeriesRef.current = chart1.addCandlestickSeries({
            upColor: '#f4f802',
            downColor: '#e42e07',
            borderDownColor: '#e42e07',
            borderUpColor: '#f4f802',
            wickDownColor: '#e42e07',
            wickUpColor: '#f4f802',
        });

        // Create the chart Histo
        const chart2 = createChart(chartContainerRefHisto.current, {
            width: chartContainerRefHisto.current.clientWidth,
            height: window.screen.height*0.15,
            handleScale:false,
            handleScroll:false,
            layout: {
                background: { type: ColorType.Solid, color: "#131722" },
                textColor: '#D9D9D9',
                fontSize:10
            },
            grid: {
                vertLines: { color: '#2B2B43' },
                horzLines: { color: '#2B2B43' },
            },
            crosshair: {
                mode: 0,
                vertLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
                horzLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
            },
            rightPriceScale: { borderColor: '#2B2B43' },
            timeScale: {
                borderColor: '#4A4A6A',
                timeVisible: true,
                secondsVisible: true,
                minBarSpacing: 0.5,
                barSpacing: 5,
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    const date = new Date(time * 1000);
                    const day = date.getDate();
                    const month = date.toLocaleString(locale, { month: 'short' });
                    const hour = String(date.getHours()).padStart(2, '0');
                    const minute = String(date.getMinutes()).padStart(2, '0');
                    return `${day} ${month} ${hour}:${minute}`;
                },
                ticksVisible: true,
                rightOffset:12,
            },
        });

        // Add histogram series
        histoSeriesRef.current = chart2.addHistogramSeries({
        });

        // Create the chart Histo2
        const chart3 = createChart(chartContainerRefHisto2.current, {
            width: chartContainerRefHisto2.current.clientWidth,
            height: window.screen.height*0.15,
            title:"BULISHNESS",
            handleScale:false,
            handleScroll:false,
            
            layout: {
                background: { type: ColorType.Solid, color: "#131722" },
                textColor: '#D9D9D9',
                fontSize:10
            },
            grid: {
                vertLines: { color: '#2B2B43' },
                horzLines: { color: '#2B2B43' },
            },
            crosshair: {
                mode: 0,
                vertLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
                horzLine: {
                    color: '#758696',
                    width: 1,
                    style: 1,
                },
            },
            rightPriceScale: { borderColor: '#2B2B43' },
            timeScale: {
                borderColor: '#4A4A6A',
                timeVisible: true,
                secondsVisible: true,
                minBarSpacing: 0.5,
                barSpacing: 5,
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    const date = new Date(time * 1000);
                    const day = date.getDate();
                    const month = date.toLocaleString(locale, { month: 'short' });
                    const hour = String(date.getHours()).padStart(2, '0');
                    const minute = String(date.getMinutes()).padStart(2, '0');
                    return `${day} ${month} ${hour}:${minute}`;
                },
                ticksVisible: true,
                rightOffset:10,
            },
        });

        // Add histogram series2
        histoSeriesRef2.current = chart3.addHistogramSeries({
           color:"DodgerBlue"
        });

        // Fetch initial data
        const fetchData = async () => {
            try {
                const response = await fetch("https://socket.crazii.online/get-chart-data");
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();
                const data = result.data.reverse().map(d => ({
                    time: parseInt(Date.parse(d.timestamp.replace(/\./g, '-') + ":00"))/1000,
                    open: d.twb_open,
                    high: d.twb_high,
                    low: d.twb_low,
                    close: d.twb_close,
                    value_kcr:d.ksi_red<100?d.ksi_red:d.ksi_green,
                    color_kcr:d.ksi_red<100?"red":"green",
                    kcx:d.kcx,
                    kcx_buy_strategy_2:d.kcx_buy_strategy_2,
                    kcx_add_strategy_3:d.kcx_add_strategy_3
                }));

                // Sort and remove duplicates to avoid error :  Assertion failed: data must be asc ordered by time, index=15, time=1725384300000, prev time=1725384300000
                const sortedData = data.sort((a, b) => a.time - b.time).filter((item, index, arr) => index === 0 || item.time !== arr[index - 1].time);
                const kcx = sortedData.map(d => ({
                    time: d.time,
                    color:"DodgerBlue",
                    value:d.kcx
                }));

                const kcr = sortedData.map(d => ({
                    time: d.time,
                    color:d.color_kcr,
                    value:d.value_kcr
                }));

                const markers = sortedData.filter(d =>Number(d.kcx_add_strategy_3)>0).map(d => ({
                    time: d.time,
                    position: 'belowBar',
                    color: '#fbbf8d',
                    text: '❖',
                }));

                const markers2 = sortedData.filter(d =>Number(d.kcx_buy_strategy_2)>0).map(d => ({
                    time: d.time,
                    position: 'belowBar',
                    color: 'cyan',
                    text: '◆',
                }));

                const w = markers.concat(markers2);
                const diamonds = w.sort((a, b) => a.time - b.time).filter((item, index, arr) => index === 0 || item.time !== arr[index - 1].time);
                
                candlestickSeriesRef.current.setData(sortedData);
                histoSeriesRef.current.setData(kcr);
                histoSeriesRef2.current.setData(kcx);

                // Add Markers
                candlestickSeriesRef.current.setMarkers(diamonds);

                // Function to make the upper half blink
                let isBlinking = true;
                const blinkUpperHalf = () => {
                    histoSeriesRef2.current.setData(
                    isBlinking
                        ? kcx.map((d) => ({ ...d, color: 'green' })) // Make it disappear
                        : kcx // Original data to make it reappear
                    );
                    isBlinking = !isBlinking;
                };
            
                // Start blinking every 500ms
                //const blinkInterval = setInterval(blinkUpperHalf, 500);

                let draw_data=result.data[result.data.length-1];
                setKSIText(draw_data.ksi_text);
                setKCXText(draw_data.kcx_text);
                setSymbol(draw_data.symbol);
                setCandleInfo({
                    time : parseInt(Date.parse(draw_data.timestamp.replace(/\./g, '-') + ":00"))/1000,
                    open: draw_data.open,
                    high: draw_data.high,
                    low: draw_data.low,
                    close:draw_data.close,
                });

                // OP
                let op_line=candlestickSeriesRef.current.createPriceLine({
                    price: result.data[result.data.length-1].op_line,
                    color: 'white',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: false,
                    title: 'OP',
                    style:{position:"right"}
                });

                 // KCX
                 let kcx_line=candlestickSeriesRef.current.createPriceLine({
                    price: result.data[result.data.length-1].kcx_blink_bar,
                    color: 'green',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: false,
                    title: 'KCX',
                    style:{position:"right"}
                });

                // MLP
                let mlp_line=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.mlp_line,
                    color: 'khaki',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'MLP',
                });

                // KTR + 1 
                let ktr_plus_1=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_plus_1,
                    color: 'LawnGreen',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR+1',
                });

                // KTR + 2 
                let ktr_plus_2=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_plus_2,
                    color: 'yellow',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR+2',
                });

                // KTR + 3 
                let ktr_plus_3=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_plus_3,
                    color: 'GoldenRod',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR+3',
                });

                // KTR - 1 
                let ktr_minus_1=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_minus_1,
                    color: 'LawnGreen',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR-1',
                });

                // KTR - 2 
                let ktr_minus_2=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktr_minus_2,
                    color: 'yellow',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR-2',
                });

                // KTR - 3 
                let ktf_minus_3=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ktf_minus_3,
                    color: 'GoldenRod',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'KTR-3',
                });

                // Pivote-1 
                let pivot_1=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.pivot_1,
                    color: draw_data.pivot_colour,
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'Pivot-1',
                });

                // Pivote-2 
                let pivot_2=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.pivot_2,
                    color: draw_data.pivot_colour,
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'Pivot-2',
                });

                // MA-200
                let ma_200=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.ma_200,
                    color: 'orange',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'MA-200',
                });

                // WMA-200
                let wma=candlestickSeriesRef.current.createPriceLine({
                    price: draw_data.wma,
                    color: 'Chartreuse',
                    lineWidth: 1,
                    lineStyle: LineStyle.Dotted, 
                    axisLabelVisible: true,
                    title: 'WMA',
                });

                if(draw_data.tp1>0){
                    // TP-1
                    candlestickSeriesRef.current.createPriceLine({
                        price: draw_data.tp1,
                        color: draw_data.tp1_colour,
                        lineWidth: 1,
                        lineStyle: LineStyle.Dotted, 
                        axisLabelVisible: true,
                        title: 'TP-1',
                    });
                }
    
                if(draw_data.tp2>0){
                    // TP-2
                    candlestickSeriesRef.current.createPriceLine({
                        price: draw_data.tp2,
                        color: draw_data.tp2_colour,
                        lineWidth: 1,
                        lineStyle: LineStyle.Dotted, 
                        axisLabelVisible: true,
                        title: 'TP-2',
                    });
                }
    
                if(draw_data.tp3>0){
                    // TP-3
                    candlestickSeriesRef.current.createPriceLine({
                        price: draw_data.tp3,
                        color: draw_data.tp3_colour,
                        lineWidth: 1,
                        lineStyle: LineStyle.Dotted, 
                        axisLabelVisible: true,
                        title: 'TP-3',
                    });
                }
               
                // Synchronize chart1 with chart2 and chart3
                syncCharts(chart1, [chart2, chart3]);
                syncCharts(chart2, [chart1, chart3]);
                syncCharts(chart3, [chart1, chart2]);

                // Function to adjust text size dynamically based on zoom level
                /*const adjustTextSize = (zoomLevel) => {
                    // Adjust marker text
                    const textSize = calculateTextSize(zoomLevel);
                    let m = diamonds.map((marker) => ({
                        ...marker
                    }));
                    candlestickSeriesRef.current.setMarkers(m);

                    // Adjust chart text size
                    chart1.applyOptions({
                        layout: {
                            fontSize: textSize, 
                        },
                    });
                };

                // Example text size calculation logic
                const calculateTextSize = (zoomLevel) => {
                    if (zoomLevel > 100) return 12; // Minimum text size
                    if (zoomLevel < 20) return 20; // Maximum text size
                    return 20 - (zoomLevel - 20) / 4; // Scale between 12px and 20px
                };

                // Subscribe to visible range changes
                chart1.timeScale().subscribeVisibleLogicalRangeChange((logicalRange) => {
                    if (logicalRange) {
                        const zoomLevel = logicalRange.to - logicalRange.from;
                        console.log('Zoom Level:', zoomLevel); // Debugging
                        adjustTextSize(zoomLevel);
                    }
                });*/
                
            } catch (error) {
                console.error("Failed to fetch chart data:", error);
            }
        };

        fetchData();

        // Subscribe to crosshair movements
        chart1.subscribeCrosshairMove(param => {
            const price = param.time && param.seriesData.get(candlestickSeriesRef.current);
            if (price) {
                setCandleInfo({
                    time: param.time,
                    open: price.open,
                    high: price.high,
                    low: price.low,
                    close: price.close,
                });
            } else {
                //setCandleInfo(null);
            }
        });

        // Resize the chart on window resize (debounced)
        let resizeTimeout;

        // Sync the time scales
        const syncCharts = (sourceChart, targetCharts) => {
            sourceChart.timeScale().subscribeVisibleTimeRangeChange((newTimeRange) => {
                targetCharts.forEach((targetChart) => {
                    targetChart.timeScale().setVisibleRange(newTimeRange);
                });
            });
        };
    
        // Resize charts on window resize
        const handleResize = () => {
            const width = chartContainerRef.current.clientWidth;
            chart1.applyOptions({ width });
            chart2.applyOptions({ width });
            chart3.applyOptions({ width });
        };
    

        // Forward Synchronization

        window.addEventListener('resize', handleResize);

        // Socket connection for real-time updates
        const socket = io('https://socket.crazii.online');
        let lastUpdate = 0;
        const updateInterval = 1000; // Update every second

        socket.on('connect', () => {
            console.log('socket on connect');
        });

        socket.on('disconnect', () => {
            console.log('socket on disconnect');
        });

        socket.on("chart", (chart) => {
            //console.log('socket on chart', chart);

            const now = Date.now();
            if (now - lastUpdate < updateInterval) return;

            lastUpdate = now;
            const time = parseInt(Date.parse(chart[4].replace(/\./g, '-') + ":00"))/1000;
            const open = parseFloat(chart[5]);
            const high = parseFloat(chart[6]);
            const low = parseFloat(chart[7]);
            const close = parseFloat(chart[8]);
            const kcx = parseFloat(chart[40]);
            const ksi_red=parseFloat(chart[44]);
            const ksi_green=parseFloat(chart[45]);
            let draw_data={
                "buffer":chart[0],
                "is_first_tick":chart[1],
                "symbol":chart[2],
                "timeframe":chart[3],
                "timestamp":chart[4],
                "twb_open":chart[5],
                "twb_high":chart[6],
                "twb_low":chart[7],
                "twb_close":chart[8],
                "open":chart[9],
                "high":chart[10],
                "low":chart[11],
                "close":chart[12],
                "pivots_buy_strategy_1":chart[13],
                "pivots_sell_strategy_1":chart[14],
                "kcx_symbol":chart[15],
                "op_line":parseFloat(chart[16]),
                "mlp_line":parseFloat(chart[17]),
                "ktr_plus_1":parseFloat(chart[18]),
                "ktr_plus_2":parseFloat(chart[19]),
                "ktr_plus_3":parseFloat(chart[20]),
                "ktr_minus_1":parseFloat(chart[21]),
                "ktr_minus_2":parseFloat(chart[22]),
                "ktf_minus_3":parseFloat(chart[23]),
                "pivot_1":parseFloat(chart[24]),
                "pivot_2":parseFloat(chart[25]),
                "pivot_colour":chart[26],
                "tp1":parseFloat(chart[27]),
                "tp1_colour":chart[28],
                "tp2":parseFloat(chart[29]),
                "tp2_colour":chart[30],
                "tp3":parseFloat(chart[31]),
                "tp3_colour":chart[32],
                "wma":parseFloat(chart[33]),
                "wma_symbol":chart[34],
                "ma_200":parseFloat(chart[35]),
                "gth_start":chart[36],
                "gth_end":chart[37],
                "gth2_start":chart[38],
                "gth2_end":chart[39],
                "kcx":parseFloat(chart[40]),
                "kcx_text":chart[41],
                "kcx_blink_bar":chart[42],
                "kcx_blink_bar_candles_back":chart[43],
                "ksi_green":parseFloat(chart[44]),
                "ksi_red":parseFloat(chart[45]),
                "ksi_text":chart[46],
                "terminating_character":chart[47],
                "kcx_buy_strategy_2":chart[48],
                "kcx_add_strategy_3":chart[49]
            }

            setCandleInfo({
                time: time,
                open: open,
                high: high,
                low: low,
                close:close,
            });
            
            candlestickSeriesRef.current.update({
                time,
                open,
                high,
                low,
                close,
            });

            histoSeriesRef.current.update({
                time,
                value:ksi_red<100?ksi_red:ksi_green,
                color:ksi_red<100?"red":"green",
            });

            histoSeriesRef2.current.update({
                time: time,
                value:kcx,
                color:"DodgerBlue"
            });

            /* Live Update */

            setKSIText(draw_data.ksi_text);
            setKCXText(draw_data.kcx_text);
            setSymbol(draw_data.symbol);
            
            setCandleInfo({
                time: parseInt(Date.parse(draw_data.timestamp.replace(/\./g, '-') + ":00"))/1000,
                open: draw_data.open,
                high: draw_data.high,
                low: draw_data.low,
                close:draw_data.close,
            });
        });

        // Clean up on component unmount
        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener('resize', handleResize);
            if (chart1) {
                chart1.remove();
                chart2.remove();
                chart3.remove();
            }
            candlestickSeriesRef.current.current = null;
            socket.disconnect();
        };
    }, []);

    return (
        <div className='chart-container'>
            <div ref={chartContainerRef} style={{ 
                width: '100%',
            }}>
                <div  style={{position:"absolute",zIndex:99999}}>
                    <p style={{color:"#fff",margin:0,fontSize:12,fontWeight:"bold", marginLeft:10}}>
                        {symbol} &nbsp;
                        { candleInfo
                            ? `O: ${candleInfo.open} H: ${candleInfo.high} L: ${candleInfo.low} C: ${candleInfo.close}`
                            : ''}
                    </p>
                </div>
            </div>
            <div ref={chartContainerRefHisto} style={{ 
                width: '100%',
            }}>
                <div  style={{position:"absolute",zIndex:99999}}>
                    <p style={{color:"#fff",margin:0,fontSize:10,fontWeight:"bold", marginLeft:10}}>
                        
                    </p>
                </div>
                <div  style={{}} className='title-indicator'>
                    <p style={{color:"#ffff00",margin:0,fontSize:10,fontWeight:"bold"}}>{ksi_text}</p>
                </div>
            </div>
            
            <div ref={chartContainerRefHisto2} style={{  
                width: '100%',
            }} >
                <div  style={{position:"absolute",zIndex:99999}}>
                    <p style={{color:"#fff",margin:0,fontSize:10,fontWeight:"bold", marginLeft:10}}>
                        
                    </p>
                </div>
                <div  style={{}} className='title-indicator'>
                    <p style={{color:"#ffff00",margin:0,fontSize:10,fontWeight:"bold"}}>{kcx_text}</p>
                </div>
            </div>
        </div>
    );
};

export default TradingChart;
